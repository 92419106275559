import 'normalize.css/normalize.css';
import 'vuetify/dist/vuetify.min.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@/utils/registerServiceWorker';
import Vue from 'vue';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import VueAxios from 'vue-axios';
import VueToasted from 'vue-toasted';
import VueCookies from 'vue-cookies';
import VueAuth from '@websanova/vue-auth';
//global
import config from '@/config';
import store from '@/store';
import GlobalMixin from "@/mixins/global";
//admin
import vuetify from '@/admin/plugins/vuetify';
import adminApp from '@/admin/index';
import router from '@/admin/router';
import i18n from '@/admin/locale'; // Internationalization
import VueCurrencyInput from 'vue-currency-input'
import ServiceLoader from "@/admin/services/loader";


Vue.use(VueCurrencyInput, {globalOptions: {currency: 'VND', locale: 'vn'}})

if (!window.VueComp) window.VueComp = {
  Others: [],
  Config: config
}
window.Pusher = require('pusher-js');
window._ = require('lodash');

const adminEl = document.getElementById('admin');
if (adminEl) {
  axiosRetry(axios, {retries: 3});
  window.axios = axios;
  window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  Vue.use(VueAxios, window.axios)
  Vue.axios.defaults.baseURL = '/api';

  Vue.use(VueToasted)
  Vue.use(VueCookies);
  Vue.mixin(GlobalMixin);
  Vue.config.productionTip = false;

  Vue.router = router;
  Vue.use(VueAuth, config.auth().VueAuth);
  const plugins = {
    router,
    store,
    i18n,
    vuetify
  }

  window._VueAdminApp = new Vue({
    ...plugins,
    render: (h) => h(adminApp),
  }).$mount('#admin');

  //load service with embed vue plugins
  ServiceLoader({
    ...plugins,
    auth: window._VueAdminApp.$auth,
    axios: window.axios,
    toasted: window._VueAdminApp.$toasted,
  });

  axios.interceptors.request.use(function (config) {
    window._VueAdminApp.$store.dispatch('system/fireRequest', config)
    return config;
  }, function (error) {
    window._VueAdminApp.$store.dispatch('system/addResponse', error.response)
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    window._VueAdminApp.$store.dispatch('system/addResponse', response)
    return response;
  }, error => {
    window._VueAdminApp.$store.dispatch('system/addResponse', error.response)
    return Promise.reject(error);
  });
}
